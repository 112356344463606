.App {
  text-align: center;
}

.App-logo {
  /* position: absolute;
  top: 10px;
  right: 50vw; */
  height: 7vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100svh;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  font-size: calc(7px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes glowing {
  0% {
    border-color: #139cce;
    box-shadow: 0 0 5px #33eafb;
  }
  50% {
    border-color: #2cc9d1;
    box-shadow: 0 0 20px #33eafb;
  }
  100% {
    border-color: #1bdee8;
    box-shadow: 0 0 5px #33eafb;
  }
}


.txt-l{
  text-align: left;
}

.txt-r{
  text-align: right;
}

.txt-small{
  font-size: 1.6vmin;
}


.txt-big{
  font-size: 2vmin;
  font-weight: bold;
  /* color: #282c34; */
}



.mbutton{
  height: 50px;
  width: 130px;
  background-color: #282c34;
  color: #61dafb;
  border-color: #61dafb;
  border-radius: 10px;
  font-size: large;
  margin: 15px;
  animation: glowing 1300ms infinite;

}

.mbutton:hover{
  background-color: #61dafb;
  color: #282c34;
  border-color: #282c34;

}

/* button:not(:focus){
  background-color: #61dafb;
  color: #282c34;
  border-color: #282c34;

} */

.bg-react{
  background-color: #282c34;
  /* background-color: aliceblue; */
}

.m-2{
  margin-bottom: 50px;
}

.nav-bar ul {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  padding:25px;
  font-size: x-large;
}

.nav-bar ul li{
  list-style-type: none;
  color: #61dafb;
  text-decoration: none;
}

.nav-bar ul li a{
  list-style-type: none;
  color: #61dafb;
  text-decoration: none;

}

.nav-bar ul li a:hover{
  color: #b4e9f7;
  scale: 1.1;
  text-decoration: none;
}




.defText{
  color: #61dafb;
}

input,select,textarea{
  border-radius: 7px;
}



/* Basic Table */
.ytable {
  width: 100%;
  cursor: pointer;
  color: #61dafb;
  background-color: #282c34;
  border-collapse: collapse;
}

.ytable th, .ytable td {
  padding: 8px;
  text-align: left;
  /* color: #61dafb;
  background-color: #282c34; */
  border-bottom: 1px solid #ddd;
}

/* Striped yTable */
.ytable-striped tbody tr:nth-of-type(odd) {
  background-color: #3b414d;
  color: #61dafb;
}

.ytable-striped tbody tr:hover {
  background-color: #139cce !important;
  color: #282c34 !important;
  /* font-weight: bold; */
}

.ytable tbody tr:hover {
  background-color: #139cce !important;
  color: #282c34 !important;
  /* font-weight: bold; */
}


.legend-content{
  font-size: 1.1rem;
  border: 1px solid rgba(97, 218, 251,0.5);
  padding: 10px 0 10px 0px;
  border-radius: 10px;
  color: #61dafb;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}


.legend-content:hover{
  font-size: 1.1rem;
  border: 1px solid rgba(97, 218, 251,0.5);
  padding: 10px 0 10px 0px;
  border-radius: 10px;
  color: #c3ecf8;
  font-weight: bold;
  text-shadow: 1px 0px #b8e5f3;

}


.fieldset{
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}
/* .ytable th:hover, .ytable td:hover {
  padding: 8px;
  text-align: left;
  background-color: #61dafb;
  color: #282c34;
  border-bottom: 1px solid #ddd;
} */


.h1,p,ul li{
  color: white !important;
}
h2 {
  color: #61dafb !important;
  text-align: center;
}

.h5{
  color: #fff !important;
  padding: 10px;
  font-weight: 400 !important;
}

h1{
  text-align: center;
}
.container-80{
  width: 80% ;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 75px;
  padding: 0 80px 0 80px;
  text-align: justify ;
}

.container-60{
  width: 60% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 100px;
  padding: 0 100px 0 100px;
  text-align: center ;
}


.container-60 ul li{
  list-style-type: none;
}

.vedio{
  width: 100% !important;
  height: auto !important;
  margin: 0px auto 0px auto;
}


.ul-none{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width:740px) {
  .nav-bar ul{
    /* display: none; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: width 2s ease-out;
    gap: 5px;
  }


  .rlg{
    display: none !important;
  }

  .container-80{
    width: 95% !important;
    padding: 0px 8% 0px 8%;
  }


  .legend-content{
    font-size: 0.5rem !important;
    width: 100%;
    margin: auto;
  }
  .mbutton{
    scale: 0.8;
    font-size: small;
  }
  
  .legend-content{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .App-header {
    margin-top: 0px;
    font-size: calc(5px + 1vmin);
    overflow: scroll;
  }

  textarea{
    margin-top: 35px !important;
  }

}

